.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-overflow-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

// Utilities
// Flexbox
.flex {
  display: flex;

  &-col {
    @extend .flex;

    flex-direction: column;
  }
}

.grid {
  display: grid;
}

.grow-0 {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.min-h-full {
  min-height: 100%;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.font-montserrat {
  font-family: 'Montserrat';
  --ion-font-family: Montserrat;
}

.text-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}

.text-inherit {
  font-size: inherit;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.opacity-40 {
  opacity: 0.4;
}

.overflow-x-auto {
  overflow-x: auto;
}

.ml-auto {
  margin-left: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.fs-px-18 {
  font-size: 18px;
  line-height: 28px;
}

.fs-px-20 {
  font-size: 20px;
}

.fs-px-24 {
  font-size: 24px;
}

.fs-px-30 {
  font-size: 30px;
}

.font-bold {
  font-weight: bold;
}

.gap-4 {
  gap: 1rem;
}

.gap-px-4 {
  gap: 4px;
}

.gap-px-10 {
  gap: 10px;
}

.gap-px-16 {
  gap: 16px;
}

.gap-px-30 {
  gap: 30px;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.block {
  display: block;
}

.font-light {
  font-weight: 300;
}

.custom-flex {
  &-row {
    @extend .flex;

    > * {
      margin-right: calc(var(--custom-flex-gap, 0) / 2);
      margin-left: calc(var(--custom-flex-gap, 0) / 2);

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }

      &:only-child {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }

  &-row-padding {
    @extend .flex;

    > * {
      padding-right: calc(var(--custom-flex-gap, 0) / 2);
      padding-left: calc(var(--custom-flex-gap, 0) / 2);

      &:first-child {
        padding-left: 0px;
      }

      &:last-child {
        padding-right: 0px;
      }

      &:only-child {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  &-col {
    @extend .flex;
    flex-direction: column;

    & > * {
      margin-bottom: calc(var(--custom-flex-gap, 0) / 2);
      margin-top: calc(var(--custom-flex-gap, 0) / 2);

      &:first-child {
        margin-top: 0px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:only-child {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}

.pointer-events-none {
  pointer-events: none;
}

.flex-gap-px-0 {
  > * {
    --custom-flex-gap: 0px;
  }
}

.flex-gap-px-8 {
  > * {
    --custom-flex-gap: 8px;
  }
}

.flex-gap-px-16 {
  > * {
    --custom-flex-gap: 16px;
  }
}

.flex-gap-px-32 {
  > * {
    --custom-flex-gap: 32px;
  }
}

// Flex Utilities
// --------------------------------------------------
// Creates flex classes to align flex containers
// and items

// Align Self
// --------------------------------------------------

// Justify Content
// --------------------------------------------------

@media screen and (width >= 992px) {
  .ion-justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .ion-justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .ion-justify-content-lg-center {
    justify-content: center !important;
  }
}

@media screen and (width >= 1200px) {
  .ion-justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .ion-justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .ion-justify-content-xl-center {
    justify-content: center !important;
  }
}

.custom-content-evenly {
  display: flex;
  /* This is a hack to make space-evenly work properly on devices using an older WebView. */
  justify-content: space-between;
  &:before,
  &:after {
    content: '';
    display: block;
  }
}

/* Font Styles */
// --------------------------------------------------
.italic {
  font-style: italic;
}

/* Text Transform */
.capitalize {
  text-transform: capitalize;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}
