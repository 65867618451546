/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import '~@ionic/angular/css/core.css'; /* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/normalize.css'; /* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css'; /* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import './theme/variables.scss';
@import './theme/mixins.scss';
@import './theme/utils.scss';
@import './theme/roboto.scss';
@import './theme/reset-style.scss';
@import './logrocket.scss';

.error {
  color: var(--ion-color-danger) !important;
}

.hidden {
  visibility: hidden;
}

/* Modals */
.meal-plan-modal {
  --height: 228px;
}

.hardware-test-modal {
  --height: 550px;
}

.pos-idle-modal {
  --width: 300px;
  --height: 150px;
}

.refunds-modal {
  --height: 100%;
  --width: 100%;
}

.add-modifier-modal {
  --width: 350px;
  --height: 550px;
}

.edit-modifier-modal {
  --width: 350px;
  --height: 315px;
}

.small-modal {
  --width: 350px;
}

.prompt-assignment-modal {
  --width: 450px;
  --height: 450px;
}

.custom-alert-error {
  --max-width: 500px;

  @media screen and (width >= 991px) {
    --max-width: 400px;
  }
}

ion-searchbar {
  --box-shadow: none !important;

  .searchbar-input-container {
    border: 1px solid #ccc;
    border-radius: 3px;
  }
}

.pos-manager-option-dropdown {
  .ng-dropdown-panel .scroll-host {
    max-height: 130px;
  }
}

.sc-ion-alert-md-h {
  --min-width: 260px;
  --max-width: 290px;
}

ion-modal {
  &.default-range-picker-modal {
    --width: 500px;
    --height: 300px;
    --border-radius: 4px;
  }

  &.disabled-advanced-schedule-modal {
    --width: 600px;
    --height: 200px;
    --border-radius: 4px;
  }

  &.schedule-advanced-time-modal {
    --width: 80%;
    --max-width: 750px;
    --max-height: 625px;
    --height: 80%;
    --border-radius: 4px;
  }

  &.appointment-edit-confirmation {
    --width: 450px;
    --height: 290px;
    --border-radius: 4px;
  }

  &.appointment-conflict-modal {
    --width: 450px;
    --border-radius: 4px;
    --height: 520px;
  }

  &.reservation-added-modal {
    --border-radius: 4px;
    --width: 615px;
    --height: 194px;
  }

  &.reservation-delete-modal {
    --border-radius: 4px;
    --width: 332px;
    --height: 180px;
  }

  &.reservation-table-modal {
    --border-radius: 4px;
    --width: 315px;
    --height: 450px;
    --backdrop-opacity: 0.32 !important;
  }

  &.dining-floorplan-diagram-modal {
    --width: 100%;
    --height: 100%;
  }

  &.delivery-instructions-modal {
    --border-radius: 10px;
  }

  &.select-payment-terminal-modal {
    --border-radius: 12px;

    @media only screen and (width >= 450px) {
      --width: 400px;
    }

    @media only screen and (width >= 768) and (height >= 768) {
      --width: 400px;
    }
  }

  &.gift-card-modal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 40%) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  }

  &.loading-modal {
    --border-radius: 6px;
  }

  &.dynamic-width-modal {
    --width: auto;
  }

  &.dynamic-height-modal {
    --height: auto;
    --max-height: 90vh;

    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }
  }

  &.pos-modifier-prompts-modal {
    --height: 80vh;

    &.self-service-modifier-prompts-modal {
      --height: auto;
      --max-height: 60vh;
      --min-width: 80vw;
      --border-radius: 12px;

      font-size: 24px;

      .ion-page {
        position: relative;
        display: block;
        contain: content;
      }
    }
  }

  &.pos-takeout-setup {
    --width: 384px;
    --height: 536px;

    @media screen and (height <= 536px) {
      --height: 100vh;
    }
  }

  &.pos-confirm-modal {
    --width: 450px;
  }

  &.login-keyboard-modal {
    --width: 100%;
    --height: 100%;
    --max-width: 500px;
    --max-height: 700px;
    --border-radius: 12px;
  }

  &.self-service-gateway-transaction-modal {
    --border-radius: 12px;
  }

  &.payment-locations-warning-modal {
    pointer-events: none;

    &::part(backdrop) {
      pointer-events: none;
    }
  }
}

ion-item.selectAllCheckBox {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
  --min-height: 0;
  --border-color: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --ripple-color: transparent;

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 26px;

    ion-checkbox {
      margin: 0;
    }
  }
}

ion-menu {
  &[menuid='tools'] {
    --width: 320px;
  }
}

ion-alert {
  &.reached-max-amount-alert {
    --width: 450px;
    --max-width: 450px;
    --height: 200px;
    --max-height: 200px;

    .alert-wrapper {
      padding: 16px;
    }

    .alert-title,
    .alert-message {
      font-size: 16px;
      text-align: left;
    }

    .alert-button {
      background: #2c333e;
      color: #fff;
      font-weight: 500;
      border-radius: 9999px;
    }

    .alert-button-group {
      margin-top: auto;
    }
  }
}

.myDiagramDiv {
  background: var(--color-whitesmoke);
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
  border: 1px solid black;
}
