@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  src: url('/assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url('/assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url('/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}
