//Logrocket Ionic Incompatible Fixes
ion-item {
  max-width: unset;
  display: block;
  flex: unset;
  --border-width: 0px;
  --padding-start: 20px;
  --border-style: solid;
  --border-width: 0;
  --border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
  );
  --min-height: 48px;

  &.ion-color:not(.item-fill-solid):not(.item-fill-outline) {
    &::part(native) {
      color: var(--ion-color-contrast);
      background: var(--ion-color-base);
    }
  }

  &.item-lines-full {
    --border-width: 0px 0px 0.55px 0px;
  }

  &::part(native) {
    display: flex;
    width: 100%;
    position: relative;
    padding-left: unset;
    padding-right: unset;
    padding-inline-end: var(--padding-end);
    padding-inline-start: calc(
      var(--padding-start) + var(--ion-safe-area-left, 0px)
    );
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    background: var(--background);
    color: inherit;
    min-height: var(--min-height);
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    opacity: 1;
  }

  &::part(detail-icon) {
    position: absolute;
    display: inline-block;
    width: 1em;
    height: 1em;
    right: 0;
  }

  &.item-lines-none {
    --inner-border-width: 0px;
    --show-inset-highlight: 0;
  }

  &.item-interactive {
    --border-width: 0 0 1px 0;
    --inner-border-width: 0;
    --show-full-highlight: 1;
    --show-inset-highlight: 0;
  }

  &.item-lines-full {
    --border-width: 0px 0px 0.55px 0px;
    --show-full-highlight: 1;
    --show-inset-highlight: 0;
  }

  &.item-fill-outline {
    --ripple-color: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    --border-color: var(--ion-color-step-500, gray);
    --border-width: 1px;
    border: none;
    overflow: visible;
  }
}

.ion-item-content {
  position: absolute;
  top: 0;
  left: var(--padding-start);
  right: var(--inner-padding-end, 0);
  bottom: 0;
  display: flex;
  align-items: center;
}

ion-grid {
  opacity: 1 !important;
  position: relative;
  display: block;
  flex: 1 1 0%;
  width: 100%;
  height: auto;
  background-color: transparent;
  contain: unset;
}

ion-row {
  display: flex;
  width: 100%;
}

ion-select {
  display: flex;
  position: relative;

  &::part(text) {
    flex: 1 1 0%;
    min-width: 16px;
    font-size: inherit;
    text-overflow: ellipsis;
    white-space: inherit;
    overflow: hidden;
  }
}

ion-menu {
  &::part(container) {
    &.menu-inner {
      transform: unset;
    }
  }
}

ion-modal {
  --background: var(--ion-background-color, #fff);
  background: unset;

  @media screen and (min-width: 768px) and (min-height: 768px) {
    --width: 600px;
    --height: 600px;
  }

  &.overlay-hidden {
    display: none;
  }

  &.show-modal {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &::part(backdrop) {
    opacity: var(--backdrop-opacity, 0.32);
    z-index: 2;
  }

  &::part(content) {
    opacity: 1;
    width: var(--width, 100%);
    height: var(--height, 100%);
    max-height: var(--max-height, 100%);
    z-index: 10;
    background: var(--background);
  }
}

ion-text {
  color: var(--ion-color-base);
}

ion-card {
  display: block;
  position: relative;
  font-size: 14px;
  border-radius: 4px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  --background: var(
    --ion-card-background,
    var(--ion-item-background, var(--ion-background-color, #fff))
  );
  background: var(--background);
  color: var(--color);
  overflow: hidden;

  &.ion-color {
    color: var(--ion-color-contrast);
  }
}

ion-loading {
  > ion-backdrop {
    opacity: 0.1;
  }

  > .loading-wrapper {
    opacity: 1 !important;
  }
}

ion-checkbox {
  --border-radius: calc(var(--size) * 0.125);
  --border-width: 2px;
  --border-style: solid;
  --border-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.51);
  --checkmark-width: 3;
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
  --transition: var(--background) 180ms cubic-bezier(0.4, 0, 0.2, 1);
  --background-checked: var(--ion-color-primary, #3880ff);
  --border-color-checked: var(--ion-color-primary, #3880ff);
  --checkmark-color: var(--ion-color-primary-contrast, #fff);
  --size: 18px;
  width: var(--size);
  height: var(--size);

  &::part(container) {
    border-radius: var(--border-radius);
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    transition: var(--transition);
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);
    background: var(--background);
    box-sizing: border-box;
  }

  &.checkbox-checked {
    &::part(container) {
      border-color: var(--border-color-checked);
      background: var(--background-checked);
    }
  }
}

ion-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  contain: strict;
  fill: currentcolor;
  box-sizing: content-box !important;
}

ion-segment {
  display: flex;
  position: relative;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  background: var(--background);
  font-family: var(--ion-font-family, inherit);
  text-align: center;
  contain: paint;
  user-select: none;
}

ion-searchbar,
ion-label {
  text-align: initial;
}
