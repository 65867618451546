@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url('/assets/fonts/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  src: url('/assets/fonts/OpenSans/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('/assets/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('/assets/fonts/Montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Merriweather';
  font-weight: 400;
  src: url('/assets/fonts/Merriweather/Merriweather-Regular.ttf');
}

@font-face {
  font-family: 'Merriweather';
  font-weight: 700;
  src: url('/assets/fonts/Merriweather/Merriweather-Bold.ttf');
}

@mixin generateFont($options) {
  $size: map-get($options, 'size');
  $weight: map-get($options, 'weight');
  $color: map-get($options, 'color');
  $line-height: map-get($options, 'line-height');
  $type: map-get($options, 'type');
  $style: map-get($options, 'style');

  font-family: $type;
  font-size: $size;
  font-weight: $weight;

  @if $line-height {
    line-height: $line-height;
  }

  @if $color {
    color: $color;
  }

  @if $style {
    font-style: $style;
  }
}

@mixin triangle($size1, $size2, $color) {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $size1 $size2 0 0;
  border-color: $color transparent transparent transparent;
  transition: border-width 0.5s;
}

/*@media screen and (min-height: 992px) and (max-height: 1199px) {

}

@media screen and (min-height: 769px) and (max-height: 991px) {

}

@media screen and (min-height: 577px) and (max-height: 768px) {

}

@media screen and (max-height: 576px) {

}*/
