// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #85ad3a;
  --ion-color-success-rgb: 133, 173, 58;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #759833;
  --ion-color-success-tint: #91b54e;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #de5645;
  --ion-color-danger-rgb: 222, 86, 69;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c34c3d;
  --ion-color-danger-tint: #e16758;

  /** dark **/
  --ion-color-dark: #2c333e;
  --ion-color-dark-rgb: 29, 30, 27;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1a1a18;
  --ion-color-dark-tint: #343532;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Custom colors**/
  --ion-color-medium-2: #61666a;
  --ion-color-medium-2-rgb: 97, 102, 106;
  --ion-color-medium-2-contrast: #ffffff;
  --ion-color-medium-2-contrast-rgb: 255, 255, 255;
  --ion-color-medium-2-shade: #555a5d;
  --ion-color-medium-2-tint: #717579;

  --ion-color-orange: #f19520;
  --ion-color-orange-rgb: 241, 149, 32;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #d4831c;
  --ion-color-orange-tint: #f2a036;

  --ion-color-light-blue: #c8e1ef;
  --ion-color-light-blue-rgb: 200, 225, 239;
  --ion-color-light-blue-contrast: #000000;
  --ion-color-light-blue-contrast-rgb: 0, 0, 0;
  --ion-color-light-blue-shade: #b0c6d2;
  --ion-color-light-blue-tint: #cee4f1;

  --ion-color-whitesmoke: #f4f5f6;
  --ion-color-whitesmoke-rgb: 244, 245, 246;
  --ion-color-whitesmoke-contrast: #000000;
  --ion-color-whitesmoke-contrast-rgb: 0, 0, 0;
  --ion-color-whitesmoke-shade: #d7d8d8;
  --ion-color-whitesmoke-tint: #f5f6f7;

  --ion-color-blue: #3b9dd0;
  --ion-color-blue-rgb: 59, 157, 208;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #348ab7;
  --ion-color-blue-tint: #4fa7d5;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-light-green: #e7efd7;
  --ion-color-light-green-rgb: 231, 239, 215;
  --ion-color-light-green-contrast: #000000;
  --ion-color-light-green-contrast-rgb: 0, 0, 0;
  --ion-color-light-green-shade: #cbd2bd;
  --ion-color-light-green-tint: #e9f1db;

  --ion-color-light-orange: #fceedb;
  --ion-color-light-orange-rgb: 252, 238, 219;
  --ion-color-light-orange-contrast: #000000;
  --ion-color-light-orange-contrast-rgb: 0, 0, 0;
  --ion-color-light-orange-shade: #ded1c1;
  --ion-color-light-orange-tint: #fcf0df;

  --ion-color-ebony-clay: #2c333e;
  --ion-color-ebony-clay-rgb: 44, 51, 62;
  --ion-color-ebony-clay-contrast: #ffffff;
  --ion-color-ebony-clay-contrast-rgb: 255, 255, 255;
  --ion-color-ebony-clay-shade: #272d37;
  --ion-color-ebony-clay-tint: #414751;

  //Colors
  --color-whitesmoke: #f4f5f6;
  --color-lighter-blue: #ebf4f9;
  --color-light-blue: #c8e1ef;
  --color-blue: #3b9dd0;
  --color-dark-blue: #1f8ec6;

  --color-light-green: #e7efd7;
  --color-dark-green: #5a830e;
  --color-text: #565c65;
  //Borders
  --border-color: #d6dadc;
  --border-color-2: #e0e2e4;
  --default-border: 1px solid var(--border-color);
  --default-border-2: 1px solid var(--border-color-2);
  --text-color: #2c333e;

  // Kiosk
  --ss-dark-blue-color-2: #4b617d;
  --ss-gray-bg-color-1: #eaedf0;
  --ss-gray-bg-color-2: #d7dce2;
  --ss-gray-bg-color-3: #a1abb7;

  --ss-dark-text-color-2: #323538;
  --ss-dark-border-color: #878b90;
  --ss-dark-border-color-2: #8492a2;
  --ss-dark-border-color-3: #ffffff7e;
  --ss-dark-border-color-4: #78889d;

  --ion-color-dark-blue: #324862;
  --ion-color-dark-blue-rgb: 50, 72, 98;
  --ion-color-dark-blue-contrast: #ffffff;
  --ion-color-dark-blue-contrast-rgb: 255, 255, 255;
  --ion-color-dark-blue-shade: #2c3f56;
  --ion-color-dark-blue-tint: #475a72;

  --ion-color-dark-red: #c11027;
  --ion-color-dark-red-rgb: 191, 16, 38;
  --ion-color-dark-red-contrast: #ffffff;
  --ion-color-dark-red-contrast-rgb: 255, 255, 255;
  --ion-color-dark-red-shade: #aa0e20;
  --ion-color-dark-red-tint: #c3283d;

  --ion-color-light-red: #fbeae9;
  --ion-color-light-red-rgb: 251, 234, 233;
  --ion-color-light-red-contrast: #de5645;
  --ion-color-light-red-contrast-rgb: 222, 86, 69;
  --ion-color-light-red-shade: #f7d6d4;
  --ion-color-light-red-tint: #ffffff;

  --ion-color-ss-gray-badge-color-1: #c1c8d1;
  --ion-color-ss-gray-badge-color-1-rgb: 193, 200, 209;
  --ion-color-ss-gray-badge-color-1-contrast: #000000;
  --ion-color-ss-gray-badge-color-1-contrast-rgb: 0, 0, 0;
  --ion-color-ss-gray-badge-color-1-shade: #aab0b8;
  --ion-color-ss-gray-badge-color-1-tint: #c7ced6;

  --ion-color-ss-red-color-2: #ec2841;
  --ion-color-ss-red-color-2-rgb: 236, 40, 65;
  --ion-color-ss-red-color-2-contrast: #ffffff;
  --ion-color-ss-red-color-2-contrast-rgb: 255, 255, 255;
  --ion-color-ss-red-color-2-shade: #d02339;
  --ion-color-ss-red-color-2-tint: #ee3e54;

  --ion-color-ss-green-color-2: #93c621;
  --ion-color-ss-green-color-2-rgb: 147, 198, 33;
  --ion-color-ss-green-color-2-contrast: #000000;
  --ion-color-ss-green-color-2-contrast-rgb: 0, 0, 0;
  --ion-color-ss-green-color-2-shade: #81ae1d;
  --ion-color-ss-green-color-2-tint: #9ecc37;

  // Blue Bayoux
  --ion-color-blue-bayoux: #415e70;
  --ion-color-blue-bayoux-rgb: 65, 94, 112;
  --ion-color-blue-bayoux-contrast: #ffffff;
  --ion-color-blue-bayoux-contrast-rgb: 255, 255, 255;
  --ion-color-blue-bayoux-shade: #395363;
  --ion-color-blue-bayoux-tint: #546e7e;

  //POS Manager colors
  --ion-color-moonstone: #3a94c6;
  --ion-color-moonstone-rgb: 58, 148, 198;
  --ion-color-moonstone-contrast: #ffffff;
  --ion-color-moonstone-contrast-rgb: 255, 255, 255;
  --ion-color-moonstone-shade: #3382ae;
  --ion-color-moonstone-tint: #4e9fcc;

  --ion-color-iron: #e0e2e4;
  --ion-color-iron-rgb: 224, 226, 228;
  --ion-color-iron-contrast: #000000;
  --ion-color-iron-contrast-rgb: 0, 0, 0;
  --ion-color-iron-shade: #c5c7c9;
  --ion-color-iron-tint: #e3e5e7;

  --ion-color-red: #cf355a;
  --ion-color-red-rgb: 207, 53, 90;
  --ion-color-red-contrast: #ffffff;
  --ion-color-red-contrast-rgb: 255, 255, 255;
  --ion-color-red-shade: #b62f4f;
  --ion-color-red-tint: #d4496b;

  --ion-color-middle-green: #468453;
  --ion-color-middle-green-rgb: 70, 132, 83;
  --ion-color-middle-green-contrast: #ffffff;
  --ion-color-middle-green-contrast-rgb: 255, 255, 255;
  --ion-color-middle-green-shade: #3e7449;
  --ion-color-middle-green-tint: #599064;

  --ion-color-oxley: #72a57d;
  --ion-color-oxley-rgb: 114, 165, 125;
  --ion-color-oxley-contrast: #ffffff;
  --ion-color-oxley-contrast-rgb: 255, 255, 255;
  --ion-color-oxley-shade: #64916e;
  --ion-color-oxley-tint: #80ae8a;

  --ion-color-bright-gray: #ebeef0;
  --ion-color-bright-gray-rgb: 235, 238, 240;
  --ion-color-bright-gray-contrast: #000000;
  --ion-color-bright-gray-contrast-rgb: 0, 0, 0;
  --ion-color-bright-gray-shade: #cfd1d3;
  --ion-color-bright-gray-tint: #edf0f2;
}

.ion-color-bright-gray {
  --ion-color-base: var(--ion-color-bright-gray);
  --ion-color-base-rgb: var(--ion-color-bright-gray-rgb);
  --ion-color-contrast: var(--ion-color-bright-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bright-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-bright-gray-shade);
  --ion-color-tint: var(--ion-color-bright-gray-tint);
}

.ion-color-oxley {
  --ion-color-base: var(--ion-color-oxley);
  --ion-color-base-rgb: var(--ion-color-oxley-rgb);
  --ion-color-contrast: var(--ion-color-oxley-contrast);
  --ion-color-contrast-rgb: var(--ion-color-oxley-contrast-rgb);
  --ion-color-shade: var(--ion-color-oxley-shade);
  --ion-color-tint: var(--ion-color-oxley-tint);
}

.ion-color-middle-green {
  --ion-color-base: var(--ion-color-middle-green);
  --ion-color-base-rgb: var(--ion-color-middle-green-rgb);
  --ion-color-contrast: var(--ion-color-middle-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-middle-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-middle-green-shade);
  --ion-color-tint: var(--ion-color-middle-green-tint);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}

.ion-color-ebony-clay {
  --ion-color-base: var(--ion-color-ebony-clay);
  --ion-color-base-rgb: var(--ion-color-ebony-clay-rgb);
  --ion-color-contrast: var(--ion-color-ebony-clay-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ebony-clay-contrast-rgb);
  --ion-color-shade: var(--ion-color-ebony-clay-shade);
  --ion-color-tint: var(--ion-color-ebony-clay-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-light-blue {
  --ion-color-base: var(--ion-color-light-blue);
  --ion-color-base-rgb: var(--ion-color-light-blue-rgb);
  --ion-color-contrast: var(--ion-color-light-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue-shade);
  --ion-color-tint: var(--ion-color-light-blue-tint);
}

.ion-color-whitesmoke {
  --ion-color-base: var(--ion-color-whitesmoke);
  --ion-color-base-rgb: var(--ion-color-whitesmoke-rgb);
  --ion-color-contrast: var(--ion-color-whitesmoke-contrast);
  --ion-color-contrast-rgb: var(--ion-color-whitesmoke-contrast-rgb);
  --ion-color-shade: var(--ion-color-whitesmoke-shade);
  --ion-color-tint: var(--ion-color-whitesmoke-tint);
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-light-green {
  --ion-color-base: var(--ion-color-light-green);
  --ion-color-base-rgb: var(--ion-color-light-green-rgb);
  --ion-color-contrast: var(--ion-color-light-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-green-shade);
  --ion-color-tint: var(--ion-color-light-green-tint);
}

.ion-color-light-orange {
  --ion-color-base: var(--ion-color-light-orange);
  --ion-color-base-rgb: var(--ion-color-light-orange-rgb);
  --ion-color-contrast: var(--ion-color-light-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-orange-shade);
  --ion-color-tint: var(--ion-color-light-orange-tint);
}

.ion-color-dark-blue {
  --ion-color-base: var(--ion-color-dark-blue);
  --ion-color-base-rgb: var(--ion-color-dark-blue-rgb);
  --ion-color-contrast: var(--ion-color-dark-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-blue-shade);
  --ion-color-tint: var(--ion-color-dark-blue-tint);
}

.ion-color-dark-red {
  --ion-color-base: var(--ion-color-dark-red);
  --ion-color-base-rgb: var(--ion-color-dark-red-rgb);
  --ion-color-contrast: var(--ion-color-dark-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-red-shade);
  --ion-color-tint: var(--ion-color-dark-red-tint);
}

.ion-color-light-red {
  --ion-color-base: var(--ion-color-light-red);
  --ion-color-base-rgb: var(--ion-color-light-red-rgb);
  --ion-color-contrast: var(--ion-color-light-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-red-shade);
  --ion-color-tint: var(--ion-color-light-red-tint);
}

.ion-color-ss-gray-badge-color-1 {
  --ion-color-base: var(--ion-color-ss-gray-badge-color-1);
  --ion-color-base-rgb: var(--ion-color-ss-gray-badge-color-1-rgb);
  --ion-color-contrast: var(--ion-color-ss-gray-badge-color-1-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ss-gray-badge-color-1-contrast-rgb);
  --ion-color-shade: var(--ion-color-ss-gray-badge-color-1-shade);
  --ion-color-tint: var(--ion-color-ss-gray-badge-color-1-tint);
}

.ion-color-ss-red-color-2 {
  --ion-color-base: var(--ion-color-ss-red-color-2);
  --ion-color-base-rgb: var(--ion-color-ss-red-color-2-rgb);
  --ion-color-contrast: var(--ion-color-ss-red-color-2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ss-red-color-2-contrast-rgb);
  --ion-color-shade: var(--ion-color-ss-red-color-2-shade);
  --ion-color-tint: var(--ion-color-ss-red-color-2-tint);
}

.ion-color-ss-green-color-2 {
  --ion-color-base: var(--ion-color-ss-green-color-2);
  --ion-color-base-rgb: var(--ion-color-ss-green-color-2-rgb);
  --ion-color-contrast: var(--ion-color-ss-green-color-2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ss-green-color-2-contrast-rgb);
  --ion-color-shade: var(--ion-color-ss-green-color-2-shade);
  --ion-color-tint: var(--ion-color-ss-green-color-2-tint);
}

.ion-color-blue-bayoux {
  --ion-color-base: var(--ion-color-blue-bayoux);
  --ion-color-base-rgb: var(--ion-color-blue-bayoux-rgb);
  --ion-color-contrast: var(--ion-color-blue-bayoux-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-bayoux-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-bayoux-shade);
  --ion-color-tint: var(--ion-color-blue-bayoux-tint);
}

.ion-color-iron {
  --ion-color-base: var(--ion-color-iron);
  --ion-color-base-rgb: var(--ion-color-iron-rgb);
  --ion-color-contrast: var(--ion-color-iron-contrast);
  --ion-color-contrast-rgb: var(--ion-color-iron-contrast-rgb);
  --ion-color-shade: var(--ion-color-iron-shade);
  --ion-color-tint: var(--ion-color-iron-tint);
}

.ion-color-moonstone {
  --ion-color-base: var(--ion-color-moonstone);
  --ion-color-base-rgb: var(--ion-color-moonstone-rgb);
  --ion-color-contrast: var(--ion-color-moonstone-contrast);
  --ion-color-contrast-rgb: var(--ion-color-moonstone-contrast-rgb);
  --ion-color-shade: var(--ion-color-moonstone-shade);
  --ion-color-tint: var(--ion-color-moonstone-tint);
}

.ion-color-medium-2 {
  --ion-color-base: var(--ion-color-medium-2);
  --ion-color-base-rgb: var(--ion-color-medium-2-rgb);
  --ion-color-contrast: var(--ion-color-medium-2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-2-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-2-shade);
  --ion-color-tint: var(--ion-color-medium-2-tint);
}

//@media (prefers-color-scheme: dark) {
/*
   * Dark Colors
   * -------------------------------------------
   */

/*body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }*/

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

/*.ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }*/

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

/*.md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}*/
